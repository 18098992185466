.wrapper{
    width: 100vw;
    height: 75vh;
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1rem solid #144267;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5rem;
    box-sizing: border-box;
}

.nav-column{
    background-color: #144267;
    width: 30vw;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
}

.nav-column ul{
    list-style-type: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem
}

.nav-column ul li{
    color: #ffffff;
    font-size: 2.5rem;
    opacity: 0.25;
    line-height: 90%;
    transition: 0.25s;
    cursor: pointer;
}

.dynamic-content{
    height: 60%;
    display: flex;
    gap: 1rem;

}

.dynamic-content img{
    width: 20vw;
    height: 100%;
    object-fit: cover;
}

.dynamic-content p{
    width: 30vw;
    line-height: 200%;
}

@media(max-width:800px){
    .wrapper{
        height: calc(100vh - 5rem);
    }
    .nav-column{
        width: 100vw;
        height: 15vh;
        display: none;
    }
    .nav-column ul{
        flex-direction: row;
    }
}