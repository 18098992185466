.service-wrapper{
    margin-top: 5rem;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.s-tile-cont{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    max-width: 1200px;
    width: 90%;
    justify-items: center;
    align-items: center;
    margin: auto;
    margin-top: 5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.s-tile{
    min-width: 300px;
    max-width: 450px;
    height: 300px;
    overflow: hidden;
    background-image: url('https://img.freepik.com/free-vector/gradient-dynamic-blue-lines-background_23-2148995756.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.25);
}

.commercial-tile{
    background-image: url('https://wallpapers.com/images/hd/blue-color-background-1920-x-1200-p1724h2lvpqt5a7d.jpg');
}

.s-tile h1{
    width: 100%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.125);
}

.s-tile p{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    color: #ffffff;
    padding: 2rem;
    box-sizing: border-box;
}

.service-toggle-btn{
    position: fixed;
    top: 6rem;
    right: 1rem;
    padding: 1rem;
    color: #ffffff;
    border: none;
    background-color: #991B1B;
    transition: 0.25s;
    cursor: pointer;
}

.service-toggle-btn:hover{
    background-color: #000000;
}

.split-btn button{
    padding: 1rem;
    font-size: 2rem;
    background-color: #991B1B;
    color: #ffffff;
    border: none;
    margin: 0 1rem;
    transition: 0.25s;
    cursor: pointer;
}

.split-btn button:hover{
    background-color: #000000;
}

.split-btn h2{
    margin-bottom: 2rem;
    font-weight: normal;
    text-align: center;
}