.contact-wrapper{
    margin-top: 5rem;
    height: calc(100vh - 5rem);
    width: 100vw;
    background-image: url('https://www.redfin.com/blog/wp-content/uploads/2023/05/hill-country-texas-homes.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.contact-form{
    width: 90vw;
    max-width: 800px;
    height: 100%;
    background-color: #144267e5;
    padding: 5rem;
    box-sizing: border-box;
}

.contact-info{
    width: 90vw;
    max-width: 800px;
    background-color: #991B1B;
    padding: 5rem;
    box-sizing: border-box;
}

.contact-info h2{
    text-align: center;
    color: #ffffff;
    font-weight: lighter;
    font-size: 3rem;
    opacity: 0.25;
    margin-bottom: 1rem;
}

.contact-info h1{
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: normal;
    text-align: center;
    margin-top: 0;
    line-height: 80%;
}

.contact-info p{
    color: #ffffff;
    text-align: center;
    letter-spacing: 0.2rem;
    margin-bottom: 0;
    line-height: 80%;
}

.contact-info h1:first-of-type{
    margin-bottom: 2rem;
}

.contact-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-form input[type=text]{
    width: 100%;
    font-size: 1.1rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: none;
    box-sizing: border-box;
}

.contact-form textarea{
    width: 100%;
    font-size: 1.1rem;
    padding: 0.5rem;
    margin: 0.5rem 0;
    border: none;
    height: 8rem;
    resize: none;
    box-sizing: border-box;
}

.contact-form button{
    width: 100%;
    font-size: 1.5rem;
    padding: 0.5rem;
    background-color: #991B1B;
    color: #ffffff;
    border: none;
    box-sizing: border-box;
    margin: 0.5rem 0;
    cursor: pointer;
}

.contact-form button:hover{
    background-color: #ffffff;
    color:#991B1B
}