*{
  margin: 0;
  padding: 0;
}

.desktop-nav{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 5rem;
  background-color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  box-sizing: border-box;
}

.desktop-nav img{
  height: 3rem;
}

.desktop-nav ul{
  list-style-type: none;
  display: flex;
  gap: 2rem;
}

.desktop-nav ul li{
  color: #ffffff;
}

.mobile-nav{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 5rem;
  background-color: #000000;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  box-sizing: border-box;
}

.mobile-nav img{
  height: 3rem;
}

.menu-bars{
  height: 2rem;
  width: 2rem;
  position: relative;
}

.menu-bars div{
  width: 100%;
  height: 4px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  transition: 0.5s;
}

.menu-bars div:nth-of-type(2){
  top: calc(50% - 2px);
}

.menu-bars div:nth-of-type(3){
  top: calc(100% - 4px);
}

.links{
  position: fixed;
  top: -10rem;
  left: 0;
  width: 100vw;
  background-color: #000000;
  z-index: 90;
  transition: 0.5s;
  padding-bottom: 1rem;
}

.links ul{
  list-style-type: none;
}

.links ul li{
  color:#ffffff;
  text-align: center;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 1rem;
}

.main-content{
  background-image: url('https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png');
  background-size: cover;
  width: 100vw;
  height: 100vh;
}

.hero-white{
  width: 40vw;
  height: 30vh;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.25s;
  padding: 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-red{
  width: 60vw;
  height: 15vh;
  background-color: #991B1B;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.25s;
  padding: 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.second-white{
  width: 40vw;
  height: 100vh;
  background-color: #ffffff;
  position: fixed;
  top: 100vh;
  left: 0;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.25s;
  padding: 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.second-red{
  width: 60vw;
  height: 100vh;
  background-color: #991B1B;
  position: fixed;
  top: 100vh;
  right: 0;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.25s;
  padding: 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.service-overview{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 100vw;
  background-color: #ffffff;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.25s;
  padding: 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reviews{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 100vh;
  left: 0;
  background-color: #144267;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.25s;
  padding: 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer{
  width: 100vw;
  height: 30vh;
  position: fixed;
  top: 100vh;
  left: 0;
  background-color: #171717;
  transition: 0.5s;
  transition-timing-function: ease-in-out;
  transition-delay: 0.25s;
  padding: 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content{
  transition: 0.25s;
  transition-timing-function: ease-in-out;
  opacity: 1;
  width: 100%;
}

.line{
  width: 100%;
  height: 1px;
  background-color: #000000;
  margin: 0.5rem 0;
}

.white-line{
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  margin: 1rem 0;
}

.img{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 30vh;
  box-shadow: 1.5rem 1.5rem 0 rgba(0, 109, 199, 0.25);
}

.img:first-of-type{
  margin-bottom: 3rem;
}

.row{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
}

.column{
  width: 40vw;
}

.s-img-a{
  background-size: cover;
  width: 100%;
  height: 50vh;
  margin-bottom: 2rem;
}

.s-img-b{
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 50vh;
  margin-top: 2rem;
}

.column img{
  width: 40vw;
}

.column h4{
  font-size: 5rem;
  font-weight: 100;
  color: #ffffff;
  position: absolute;
  bottom: 5rem;
  left: 5rem;
  width: calc(50vw - 7rem);
}

.review-tile{
  color: #ffffff;
}

.review-tile h5{
  font-size: 1.5rem;
}

.footer-row img{
  height: 5rem;
}

.footer-row{
  width: 100%;
  height: auto;
  justify-content: space-between;
  align-items: flex-end;
}

.footer-row ul{
  list-style-type: none;
  display: flex;
  gap: 2rem;
}

.footer-row ul li{
  color: #ffffff;
}

.link-row{
  justify-content: flex-start;
  color: #ffffff;
  gap: 5rem;
  margin-top: 1rem;
}

.link-row ul{
  list-style-type: none;
}

.link-row ul li{
  opacity: 0.25;
}

@media(max-width:800px){
  .hero-white{
    width: 100vw;
    height: 40vh;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.9);
  }
  .hero-red{
    display: none;
  }
  .second-white{
    display: none;
  }

  .second-red{
    width: 100vw;
    padding: 2rem;
  }
  .reviews{
    padding: 2rem;
  }
  .row{
    flex-direction: column;
  }
  .column{
    width: 100%;
  }
  .review-tile h5{
    font-size: 1rem;
  }
  .review-tile p{
    font-size: 0.8rem;
  }
  .white-line{
    margin: 0.5rem 0;
  }
  .column img{
    width: 15vw;
  }
  .column h4{
    font-size: 1.8rem;
    position: relative;
    bottom: 0;
    left: 0;
    width: auto;
    line-height: 80%;
  }
  .service-text h1{
    font-size: 1rem;
  }
  .service-text p{
    font-size: 0.8rem;
  }
  .service-overview{
    padding: 4rem 2rem 2rem;
  }
  .service-overview .content .row .column:last-of-type{
    display: flex;
    flex-direction: column-reverse;
  }
  .s-img-a{
    height: 20vh;
    margin-bottom: 1rem;
  }
  .s-img-b{
    height: 20vh;
    margin-bottom: 1rem;
  }
  .footer-row{
    align-items: flex-start;
  }
  .footer-row ul{
    display: none;
  }
  .footer{
    padding: 2rem;
  }
  .footer-row img{
    height: 1.5rem;
  }
  .link-row{
    flex-direction: row;
    gap: 2rem;
  }
  .link-row ul h4{
    font-size: 1rem;
    line-height: 80%;
  }
  .link-row ul li{
    font-size: 0.8rem;
  }
  .footer p{
    font-size: 0.8rem;
  }
}


.unavail{
  color:#6b6b6b
}

.links p{
  text-align: center;
  color:#ffffff
}